// TODO: update code
import { useState, useEffect } from "react";
import { getIsPluginColumn } from "feature/columnPlugin/getIsPluginColumn";

const start = +new Date();
const check = () => {
  const now = +new Date();

  if (now - start < 10000) {
    setTimeout(() => {
      if (getIsPluginColumn()) {
        window.dataLayer?.push({ "event": "chrome-extension-installed" }); // is it for Google Tag Manager?
        Object.values(subs).forEach((cb) => cb());
        document.body.classList.add("gs-column-plugin");
      } else {
        check();
      }
    }, 250);
  }
};
const subs = {};
let nextId = 1;

check();
const useIsColumnPlugin = () => {
  const [isPlugin, setIsPlugin] = useState(getIsPluginColumn);

  useEffect(() => {
    if (isPlugin) return;
    if (getIsPluginColumn()) return setIsPlugin(true);

    const id = ++nextId;
    subs[id] = () => setIsPlugin(true);

    return () => delete subs[id];
  }, []);

  return isPlugin;
};

export default useIsColumnPlugin;
