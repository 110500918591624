import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

const WebhooksPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'webhooksPage' */ "./WebhooksPage")
      .catch( (e) => console.log("Page loading err", e) || ModuleLoaderError );
  },
  loading: Loading,
});

export default WebhooksPage;
