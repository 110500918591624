import store from "store/store";
import { injectReducer } from "store/reducers";
import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

const FlowDetailsPage = Loadable({
  loader: () => {
    const component = import(/* webpackChunkName:'FlowDetailsPage' */ "./container");

    return import(/* webpackChunkName:'FlowDetailsPage' */ "./reducer/reducer")
      .then( (reducer) => injectReducer(store, { key: "flowDetails", reducer: reducer.default }) )
      .then( () => component )
      .catch( (e) => console.log("Page loading err", e) || ModuleLoaderError );
  },
  loading: Loading,
});

export default FlowDetailsPage;
