import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";


const LoginPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName: 'ResetPassPage' */ "./container")
      .catch((e) => console.log("PAGE LOADING ERROR", e) || ModuleLoaderError);
  },
  loading: Loading,
});

export default LoginPage;
