declare global {
  interface Window {
    _isGsClientPluginColumn: boolean
  }
}
const key = "_isGsClientPluginColumn";
export const getIsPluginColumn = (): boolean => {
  const globalValue = window._isGsClientPluginColumn;
  if (globalValue) {
    window.sessionStorage.setItem(key, "1");
    return true;
  }
  const sessionValue = !!window.sessionStorage.getItem(key);

  return sessionValue;
};
