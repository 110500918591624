import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

const AsyncLeadsPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'LeadsPage' */ "./LeadsPage")
      .catch( (e) => {
        console.log("Leads Page loading err", e);
        return ModuleLoaderError;
      });
  },
  // @ts-ignore
  loading: Loading,
});

export default AsyncLeadsPage;

