import Error404 from "components/routes/NotFound";
import { useEffect } from "react";
import { getAppVersion } from "components/appVersion";

const storageKey = "appVersion";

export const ModuleLoaderError = (props: unknown) => {
  useEffect(() => {
    const prevVersion = localStorage.getItem(storageKey);
    const currentVersion = getAppVersion();

    if (prevVersion == currentVersion) return;
    localStorage.setItem(storageKey, currentVersion);
    window.location.reload();
  }, []);

  return (
    <Error404 />
  );
};
