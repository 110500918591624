import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

export { default as path } from "components/routes/SenderProfileGroup/paths";

const SenderProfilesPage = Loadable({
  loader: () => {

    return import(/* webpackChunkName:'SenderProfilesPage' */ "./SenderProfilesPage")
      .catch( (e) => console.log("Page loading err", e) || ModuleLoaderError );
  },
  loading: Loading,
});

export default SenderProfilesPage;
