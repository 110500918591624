import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

export { default as path } from "./path";

export default Loadable({
  loader: () => {
    return import(/* webpackChunkName:'LinkedinFiltersPage' */ "./LinkedinFiltersPage")
      .then((module) => module.LinkedinFiltersPage)
      .catch(() => ModuleLoaderError);
  },
  loading: Loading as any,
});
