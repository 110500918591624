import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

export const AsyncGetStartedPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'GetStartedPage' */ "./GetStartedPage")
      .then((r) => r.GetStartedPage)
      .catch( (e) => {
        console.log("Leads Page loading err", e);
        return ModuleLoaderError;
      });
  },
  // @ts-ignore
  loading: Loading,
});


