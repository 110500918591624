import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

export const AsyncReportsPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'ReportsPage' */ "./ReportsPage")
      .then((r) => r.ReportsPage)
      .catch( (e) => {
        console.log("Leads Page loading err", e);
        return ModuleLoaderError;
      });
  },
  // @ts-ignore
  loading: Loading,
});


