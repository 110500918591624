import { Route, Switch, Redirect } from "react-router-dom";
import Error404 from "components/routes/NotFound";
import { reportsPath, AsyncReportsPage } from "./ReportsPage";
import { getStartedPath, AsyncGetStartedPage } from "./GetStartedPage";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";
import { useCurrentUser } from "feature/session/useCurrentUser";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";
import store from "store/store";
import { useMemo } from "react";
import { dashboardPath } from "./path";

export const DashboardPage = () => {
  const { currentUser } = useCurrentUser();
  const isWhiteLabel = getIsWhiteLabel(store.getState());
  const tabs = useMemo(() => {
    const reportsTab = {
      label: "Reports",
      path: reportsPath,
    };

    if (isWhiteLabel || currentUser?.config.show_starter_guide === false) {
      return [reportsTab];
    }

    return [
      {
        label: "Get Started",
        path: getStartedPath,
      },
      reportsTab,
    ];
  }, [currentUser?.config.show_starter_guide, isWhiteLabel]);

  return (
    <>
      <ContentHeader>
        <ContentHeader.Tabs.Navigation tabs={tabs} />
      </ContentHeader>

      <Switch>
        <Route
          path={dashboardPath}
          exact
        >
          <Redirect to="/" />
        </Route>
        {!isWhiteLabel && (
          <Route
            path={getStartedPath}
            component={AsyncGetStartedPage}
          />
        )}
        <Route
          path={reportsPath}
          component={AsyncReportsPage}
        />
        <Route>
          <Error404 />
        </Route>
      </Switch>
    </>
  );
};

